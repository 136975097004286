<template>
	<Dialog :dialog="personDialog">
		<template v-slot:title>{{ personTitle }}</template>
		<template v-slot:body>
			<div class="min-height-280">
				<v-form
					v-if="personDialog"
					ref="personForm"
					v-model.trim="personFormValid"
					lazy-validation
					v-on:submit.stop.prevent="updatePerson"
				>
					<v-row>
						<v-col md="2" class="pt-0">
							<label for="person-salutation" class="btx-label mt-2">Title</label>
							<SelectInput
								:items.sync="listTitle"
								id="person-salutation"
								hide-details
								placeholder="Title"
								v-model="personModel.salutation"
							></SelectInput>
						</v-col>
						<v-col md="5" class="pt-0">
							<label for="person-first-name" class="btx-label mt-2">First Name</label>
							<TextInput
								id="person-first-name"
								v-model="personModel.first_name"
								hide-details
								placeholder="First Name"
								:rules="[vrules.required(personModel.first_name, 'First Name')]"
								v-on:keyup="updatePersonDisplayName()"
							></TextInput>
						</v-col>
						<v-col md="5" class="pt-0">
							<label for="person-last-name" class="btx-label mt-2">Last Name</label>
							<TextInput
								id="person-last-name"
								v-model="personModel.last_name"
								hide-details
								placeholder="Last Name"
								v-on:keyup="updatePersonDisplayName()"
							></TextInput>
						</v-col>
						<v-col md="6">
							<label for="person-mobile" class="btx-label mt-2">Display Name</label>
							<TextInput
								id="person-display-name"
								v-model="personModel.display_name"
								hide-details
								placeholder="Display Name"
							></TextInput>
						</v-col>
						<v-col md="6">
							<label for="person-mobile" class="btx-label mt-2">Designation</label>
							<SelectInput
								hide-details
								:disabled="pageLoading"
								:loading="pageLoading"
								:items.sync="designationLists"
								placeholder="Select Designation"
								item-text="value"
								item-value="value"
								:id="`designation`"
								v-model="personModel.designation"
							></SelectInput>
						</v-col>
						<v-col md="6">
							<label for="person-email" class="btx-label mt-2">Email Address</label>
							<EmailInput
								id="person-email"
								v-model="personModel.email"
								hide-details
								placeholder="Email Address"
								:rules="[vrules.required(personModel.email, 'Email Address')]"
							></EmailInput>
						</v-col>
						<v-col md="6">
							<label for="person-mobile" class="btx-label mt-2">Mobile</label>
							<PhoneInput
								id="person-mobile"
								v-model="personModel.mobile"
								hide-details
								placeholder="Mobile"
							></PhoneInput>
						</v-col>
						<v-col md="12">
							<v-checkbox
								dense
								v-model="personModel.primary"
								:false-value="0"
								:true-value="1"
								hide-details
								class="m-0 p-0"
								label="Set As Primary"
							></v-checkbox>
						</v-col>
					</v-row>
				</v-form>
			</div>
		</template>
		<template v-slot:action>
			<v-btn class="white--text" depressed color="blue darken-4" tile v-on:click="updatePerson">
				Save
			</v-btn>
			<v-btn depressed tile v-on:click="$emit('close', true)"> Close </v-btn>
		</template>
	</Dialog>
</template>
<script>
import { isEmpty } from "lodash";
import Dialog from "@/view/components/Dialog";
import TextInput from "@/view/components/TextInput";
import EmailInput from "@/view/components/EmailInput";
import SelectInput from "@/view/components/SelectInput";
import PhoneInput from "@/view/components/Phone";
import { CreateOrUpdateContactPerson } from "@/core/lib/contact.lib";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import ApiService from "@/core/services/api.service";

export default {
	name: "create-update-person",
	title: "Create Update Person",
	props: {
		personDialog: {
			type: Boolean,
			default: false,
		},
		contactUuid: {
			type: String,
			default: null,
		},
		person: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			personFormValid: true,
			personFormLoading: false,
			pageLoading: false,
			designationLists: [],
			personTitle: "Create Contact Person",
			personModel: {
				id: null,
				uuid: null,
				primary: false,
				salutation: "mr",
				first_name: null,
				last_name: null,
				display_name: null,
				email: null,
				designation: null,
				mobile: null,
			},
		};
	},
	watch: {
		person: {
			deep: true,
			handler() {
				this.updatePersonDialog();
			},
		},
	},
	methods: {
		updatePersonDisplayName() {
			const personModel = this.personModel;
			const display_name = [];
			if (personModel) {
				if (personModel.first_name) {
					display_name.push(personModel.first_name);
				}
				if (personModel.last_name) {
					display_name.push(personModel.last_name);
				}
				this.personModel.display_name = display_name.join(" ");
			}
		},
		updatePersonDialog() {
			if (this.person && !isEmpty(this.person)) {
				this.personTitle = "Update Contact Person";
				this.personModel = new Object({
					id: this.person.id || null,
					uuid: this.person.uuid || null,
					salutation: this.person.salutation || "mr",
					first_name: this.person.first_name || null,
					last_name: this.person.last_name || null,
					email: this.person.email || null,
					mobile: this.person.mobile || null,
					display_name: this.person.display_name || null,
					designation: this.person.designation || null,
					primary: this.person.primary || null,
				});
			} else {
				this.personTitle = "Create Contact Person";
				this.personModel = new Object({
					id: null,
					uuid: null,
					salutation: "mr",
					first_name: null,
					last_name: null,
					display_name: null,
					email: null,
					mobile: null,
				});
			}
		},
		getDesignations() {
			this.pageLoading = true;
			ApiService.setHeader();
			ApiService.get(`contact-options`)
				.then(({ data }) => {
					console.log(data);
					this.designationLists = data.designation;
				})
				.catch((error) => {
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		async updatePerson() {
			try {
				this.personFormLoading = true;
				await CreateOrUpdateContactPerson(this.contactUuid, {
					persons: [this.personModel],
				});
				this.$emit("success", true);
				this.$emit("close", true);
				this.$store.commit(SET_MESSAGE, [
					{
						model: true,
						message: "Success ! Contact person's information has been saved.",
					},
				]);
			} catch (error) {
				this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				this.$emit("error", true);
			} finally {
				this.personFormLoading = false;
			}
		},
	},
	components: {
		Dialog,
		TextInput,
		EmailInput,
		SelectInput,
		PhoneInput,
	},
	mounted() {
		this.updatePersonDialog();
		this.getDesignations();
	},
};
</script>
