<template>
	<v-menu v-model="showMenu" offset-y style="max-width: 600px">
		<template v-slot:activator="{ on, attrs }">
			<TextInput :v-bind-attrs="attrs" :v-on="on" hide-details placeholder="Search..."></TextInput>
		</template>

		<v-list>
			<v-list-item link v-for="row in 10" :key="row">
				<v-list-item-title>Demo 1</v-list-item-title>
			</v-list-item>
			<v-list-item>
				<v-list-item-title>Demo 2</v-list-item-title>
			</v-list-item>
		</v-list>
	</v-menu>
</template>
<script>
import TextInput from "@/view/components/TextInput";

export default {
	name: "google-search-input",
	model: {
		prop: "value",
		event: "change",
	},
	components: {
		TextInput,
	},
	props: {
		value: {
			type: [Object, Number, String],
			default: null,
		},
		id: {
			type: String,
			default: null,
		},
		label: {
			type: String,
			default: null,
		},
		dense: {
			type: Boolean,
			default: false,
		},
		hideDetails: {
			type: Boolean,
			default: false,
		},
		timePicker: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	watch: {
		value: {
			deep: true,
			immediate: true,
			handler(value) {
				this.autocompleteinput = value;
			},
		},
	},
	data() {
		return {
			autocompleteinput: null,
			menuProps: {},
			showMenu: false,
		};
	},
	methods: {
		getPlaceholder() {
			return `Select ${this.placeholder}`;
		},
	},
	mounted() {
		this.autocompleteinput = this.value;
		if (this.dense) {
			this.menuProps.contentClass = "dense-autocomplete-list";
		}
		if (this.timePicker) {
			this.menuProps.contentClass = "time-picker-autocomplete-list";
		}
	},
};
</script>
